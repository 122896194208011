#ContactMap {
  height: 54vh;
  width: 100.8%;
  margin-bottom: 3rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-right: -1rem;
}
#ContactConatiner {
  padding-left: 0px;
}
#ContactInput {
  border: 1px solid #000000;
  border-radius: 0px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
#ContactButton {
  background-color: #f7990c;
  border: none;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-family: "AR One Sans";
}
#HeadingText {
  font-size: 2.6rem;
  font-weight: 600;
  color: #f7990c;
  font-family: "AR One Sans";
}
#AddressLabel {
  text-align: justify;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "AR One Sans";
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
}

.Toast {
  background-color: red;
}

@media screen and (max-width: 480px) {
  #phoneView {
    padding-left: 1rem;
  }
  #phoneView1 {
    margin-top: 3rem;
  }
}

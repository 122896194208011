#home1 {
  text-align: center;
  color: white;
  background-color: #cf3a30;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  /* margin-top: 2rem; */
  /* border-radius: 0.5rem; */
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "Raleway";
}
#home2 {
  text-align: center;
  color: white;
  background-color: #153980;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  /* margin-top: 1rem; */
  /* border-radius: .5rem; */
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "Raleway";
}
#customer-say-label {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #190d30;
  margin-bottom: 3rem;
  font-family: "Raleway";
}

#Header1 {
  /* margin-right: 4rem; */
}
#navbar {
  background-color: white;
  color: black;
  height: 100px;
  position: relative;
}
.Header2 {
  color: black;
  background-color: white;
}
.navbar-toggler {
  background-color: #1c2752;
  color: white;
}

.slideout-overlay .gp-icon.pro-close,
.slideout-navigation .dropdown-menu-toggle {
  color: "white";
}
#Header3 {
  background: linear-gradient(to bottom right, #eabf40 0%, #ff0000 100%);
  border: none;
  border-radius: 4rem;
  min-width: 5rem;
  transition: 1s;
}
#Header3:hover {
  background: linear-gradient(to bottom left, #eabf40 0%, #ff0000 100%);
  border: none;
  border-radius: 4rem;
  min-width: 6rem;
  transform: (1.15);
  transition: transform 1s ease-in-out all;
}

#Header4 {
  background: linear-gradient(to top right, #ff0000 0%, #fe5b00 100%);
  border: none;
  border-radius: 4rem;
  min-width: 4rem;
  transition: 1s;
}
#Header4:hover {
  background: linear-gradient(to top left, #fe5b00 0%, #eabf40 100%);
  border: none;
  border-radius: 4rem;
  min-width: 6rem;
  transform: (1.15);
  transition: transform 1s ease-in-out all;
}
#Iconimg {
  width: 50px;
  height: 50px;
}
#labeltxt {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  color: black;
}
#labelbodytxt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: black;
}
#Iconcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#textcontainer {
  margin-left: 20px;
}
/* DropdownMenu.css */

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 40px;
  background-color: #6d7493;
  color: white !important;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0.3rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px); /* Start slightly above the button */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
}

.dropdown-content a {
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  flex-direction: row;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateX(-10px); /* Start slightly to the left */
}

.dropdown-content a:hover {
  color: white;
  font-weight: bold;
  transform: translateX(0); /* Move to original position */
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-radius: 0.3rem;
}

.dropdown-button:hover .dropdown-content {
  display: block;
  flex-direction: row;
  border-radius: 0.3rem;
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Move to original position */
}

.carousel-image-container {
  object-fit: cover;
  width: 100%;
  height: 85vh;
}

.carousel-image {
  width: 100%;
  height: 100%;
}

#ch_4 {
  border-radius: 3rem;
  background: #2489d3;
  padding: 0.5rem 3rem;
  margin-top: 1rem;
  color: #fff;
  font-family: Open Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.carousel .carousel-indicators button {
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #19408d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-prev {
  left: 45px;
  background-color: #19408d;
}

.carousel-control-next {
  right: 45px;
  background-color: #19408d;
}

@media only screen and (max-width: 600px) {
  .carousel-image-container {
    height: 200px; /* Adjust the height for smaller screens */
  }

  #ch_4 {
    font-size: 12px; /* Adjust button font size for smaller screens */
  }
}

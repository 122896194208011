.text-container {
  padding: 20px;
}

.gradient-text-register {
  background: #304183;
  -webkit-background-clip: text; /* Apply the gradient inside the text */
  color: transparent; /* Hide the original text color */
  font-size: 3rem;
  font-weight: bold;
}
#welBackTxtRegister {
  color: black; /* Hide the original text color */
  font-size: 2rem;
  font-weight: 700;
}
#mainCol {
  text-align: center;
}

#userTypeMainRow {
  margin-top: 1.7rem;
  margin-bottom: 0.2rem;
}

#userTypeText {
  color: white;
  font-size: 1.3rem;
  font-weight: 500;
}

#custom-textfield {
  border: 2px solid red;
  border-radius: 8px;
  padding: 10px;
}
#inputFieldLabel {
  color: #153980;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 1.1rem;
}
#inputField {
  box-shadow: none;
  background-color: #fef7f7;
  border-color: #887b7b;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
  border-radius: 1rem;
}
#registerButton {
  background: linear-gradient(92deg, #0d5af2, #902fcb);
  border: 0px solid transparent;
  width: 30%;
  border-radius: 0.8rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 1.5rem;
}
#registerButton:hover {
  background: linear-gradient(92deg, #0d5af2, #902fcb);
  border: 0px solid transparent;
  width: 30%;
  border-radius: 1rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 1.5rem;
  transition: all 0.5s ease;
  transform: scale(1.02);
}
#btnDiv {
  text-align: center;
  margin-bottom: 10rem;
}
#singUpText {
  color: #f7990c;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
}
#singUpTextDiv {
  margin-top: 1.6rem;
  text-align: center;
}

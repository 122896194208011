#openingTextCol {
  font-weight: 600;
  font-family: "Raleway";
  font-size: 14px;
}
#top-header-container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 2rem;
  padding-right: 4rem;
  background-color: #f2f2f2;
  text-align: center;
}
#icon-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#icon-style {
  margin-left: 1.3rem;
  margin-top: 0.2rem;
}

@media only screen and (max-width: 600px) {
  #top-header-container {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0rem;
    padding-right: 1.2rem;
    background-color: #f2f2f2;
    text-align: center;
  }
  #hide-col {
    display: none;
  }
}

#Spec-Head {
  font-family: "Poppins";
  font-size: 1.3rem;
  font-weight: 600;
}
#Spec-Label {
  font-family: "Poppins";
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
#Spec-Text {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
}
#Spec-Text-Div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}
#Spec-Container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
  #Spec-Text-Div {
    width: 80%;
  }
}

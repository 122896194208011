#homecompcard1 {
  border: 5px solid #CF3A30;
  margin-bottom: 2rem;
  padding: 4rem 2rem 4rem 2rem;
  width: 100%;
  transition: all 0.2s ease;
  border-radius: 1rem;
  cursor: pointer;
}

#homecompcard1:hover {
  border: 1px solid #CF3A30;
  margin-bottom: 2rem;
  background-color: #f1f1f1;
  /* padding: 3rem 2.5rem 3rem 2.5rem;
    transform: scale(50px);
    width: 100%; */
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.1);
  border-radius: 1rem;
  cursor: pointer;
}

#homecompcard2 {
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
}

#homecompcard3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1rem;
  color: #000000;
  text-align: center;
}
#homecompcard4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  padding: 20px;
}

.gradient-text {
  background: #304183;
  -webkit-background-clip: text; /* Apply the gradient inside the text */
  color: transparent; /* Hide the original text color */
  font-size: 3rem;
  font-weight: bold;
}
#welBackTxt {
  color: black; /* Hide the original text color */
  font-size: 2rem;
  font-weight: 700;
}
#mainCol {
  text-align: center;
}

#userTypeMainRow {
  margin-top: 1.7rem;
  margin-bottom: 0.2rem;
}
#selectedUserTypeCardCol {
  border: 1px solid transparent;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  border-radius: 1.2rem;
  padding: 0.6rem;
  cursor: pointer;
  background: linear-gradient(92deg, #0f52d6, #0f52d6);
}
#unSelectedUserTypeCardCol {
  border: 1px solid transparent;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 1.2rem;
  padding: 0.6rem;
  cursor: pointer;
  background: linear-gradient(92deg, black, black, #181818);
  margin-bottom: 1rem;
}
#selectedUserTypeCardCol:hover {
  border: 1px solid transparent;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.8rem;
  padding: 0.6rem;
  cursor: pointer;
  background: linear-gradient(62deg, #0f52d6, #0f52d6);
  transition: all 1s ease;
  transform: scale(1.05);
  margin-bottom: 1rem;
}
#unSelectedUserTypeCardCol:hover {
  border: 1px solid transparent;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.8rem;
  padding: 0.6rem;
  cursor: pointer;
  background: linear-gradient(62deg, black, black, #181818);
  transition: all 1s ease;
  transform: scale(1.05);
  margin-bottom: 1rem;
}
#userTypeText {
  color: white;
  font-size: 1.3rem;
  font-weight: 500;
}

#custom-textfield {
  border: 2px solid red;
  border-radius: 8px;
  padding: 10px;
}
#inputFieldLabel {
  color: #153980;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 1.1rem;
}
#inputField {
  box-shadow: none;
  background-color: #fef7f7;
  border-color: #887b7b;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
  border-radius: 0.6rem;
}

#singUpText {
  color: #f7990c;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
}
#singUpTextDiv {
  margin-top: 1.6rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .gradient-text {
    background: linear-gradient(to right, #74cb2f, #0f71a2, #0e0e0e);
    -webkit-background-clip: text; /* Apply the gradient inside the text */
    color: transparent; /* Hide the original text color */
    font-size: 1.5rem;
    font-weight: bold;
    text-align: justify;
  }
  .text-container {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #welBackTxt {
    color: black; /* Hide the original text color */
    font-size: 1rem;
    font-weight: 700;
  }
}

#about1 {
  color: #f7990c;
  font-family: "Raleway";
  font-size: 2rem;
  text-align: start;
  margin-top: 2rem;
  font-weight: bolder;
}
#about2 {
  text-align: justify;
  padding: 0.5rem;
}
#about3 {
  display: flex;
  justify-content: start;
}

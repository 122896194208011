#nav-head-container {
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}
#nav-head-col {
  background-color: #f7990c;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
}
#nav-head-div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
#navigation-div {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 60%;
}
#navigation-text {
  font-weight: 700;
  font-family: "Raleway";
  cursor: pointer;
  user-select: none;
}
#navigation-text:hover {
  color: white;
  font-weight: 700;
  font-family: "Raleway";
  cursor: pointer;
  transition: 0.5s all linear;
}
#question-btn {
  background-color: #1c2752;
  color: white;
  font-weight: 600;
  font-family: "Raleway";
  font-size: 17px;
  border: none;
}
#profile-view-div {
  width: 40px;
  height: 40px;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  background-color: #1c2752;
  color: white;
  margin-right: 0.5rem;
}
#profile-logout-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #navigation-div {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    width: 80%;
  }
  #navigation-text {
    font-weight: 700;
    font-family: "Raleway";
    font-size: 12px;
  }
  #question-btn {
    background-color: #1c2752;
    color: white;
    font-weight: 600;
    font-family: "Raleway";
    font-size: 10px;
    width: 100%;
    border: none;
  }
  #nav-head-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.backgroundFooter {
  background-color: #1c2752;
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-top: 3rem;
}
#footerIconLabel-txt {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
}
#Iconimg {
  width: 50px;
  height: 50px;
}
#footer-labelbodytxt {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
}
#Iconcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#textcontainer {
  margin-left: 20px;
}
#line-div {
  background-color: #4b557d;
  height: 1px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 2.3rem;
}
#company-description-txt {
  color: white;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  text-align: justify;
  margin-bottom: 1.5rem;
}
#icon-col {
  cursor: pointer;
}

#company-description-col {
  border-right: 2px solid #4b557d;
  padding-right: 3rem;
}

#explore-label {
  color: white;
  font-size: "Raleway";
  font-weight: 600;
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-bottom: 0.7rem;
}
#explore-body-text {
  color: white;
  font-size: "Raleway";
  font-weight: 400;
  font-size: 0.9rem;
  margin-left: 2rem;
  cursor: pointer;
}

#copyright-text {
  text-align: center;
  color: white;
  font-family: "Raleway";
  font-size: 0.9rem;
}

@media only screen and (max-width: 700px) {
  #Iconcontainer {
    margin-top: 1rem;
  }
  #explore-label {
    margin-left: 0rem;
    margin-top: 3rem;
  }
  #explore-body-text {
    margin-left: 0rem;
    cursor: pointer;
  }
  #company-description-col {
    border: 0px;
    padding-right: 1rem;
  }
}

#Heading-text {
  color: #f7990c;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
#card-service-page {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 15px;
  width: 100%;
  margin-bottom: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  min-height: 320px;
}
